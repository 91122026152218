// extracted by mini-css-extract-plugin
export var aboutMe = "AboutPage-module--aboutMe--0a26c";
export var center = "AboutPage-module--center--d3b7a";
export var connect = "AboutPage-module--connect--a7cf6";
export var connectLogo = "AboutPage-module--connectLogo--7236b";
export var content = "AboutPage-module--content--5b84f";
export var content2 = "AboutPage-module--content2--4f443";
export var footDescrip = "AboutPage-module--footDescrip--5a9a5";
export var footer = "AboutPage-module--footer--d1846";
export var frame = "AboutPage-module--frame--904f6";
export var fullsize = "AboutPage-module--fullsize--f49ea";
export var hello = "AboutPage-module--hello--4b5d0";
export var overall = "AboutPage-module--overall--10a74";
export var photo = "AboutPage-module--photo--40c96";
export var title = "AboutPage-module--title--aa59f";
export var title3 = "AboutPage-module--title3--06c6d";